<template>
  <div>
    <snt-stylesheet>
      {{ stylesheet }}
    </snt-stylesheet>
    <v-runtime-template
      :template="headerTemplate"
      :data="{ theme }" />
    <v-runtime-template
      :template="contentTemplate"
      :data="{ theme }" />
    <v-runtime-template
      :template="footerTemplate"
      :data="{ theme }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_SALEPAGETEMPLATE } from '@/resources/graphql'
import VRuntimeTemplate from './components/VRuntimeTemplate.vue'

export default {
  name: 'SalePage',
  components: {
    VRuntimeTemplate
  },
  props: {
    propName: {
      type: String,
      default: ''
    }
  },
  apollo: {
    salePageTemplate: () => ({
      query: GET_SALEPAGETEMPLATE,
      variables () {
        return {
          id: this.templateId
        }
      },
      result ({ data: { salePageTemplate } }) {
        this.stylesheet = salePageTemplate.stylesheet
        console.log(this.propName)
        const isLayout = ['header', 'footer'].includes(this.propName)
        if (!isLayout) {
          this.headerTemplate = salePageTemplate.header
          this.footerTemplate = salePageTemplate.footer
        }
        this.contentTemplate = salePageTemplate[this.propName]
      }
    })
  },
  data () {
    return {
      stylesheet: '',
      headerTemplate: '',
      contentTemplate: '',
      footerTemplate: ''
    }
  },
  computed: {
    ...mapGetters({
      subdomain: 'salePage/subdomain',
      theme: 'local/theme'
    }),
    templateId () {
      return this.$route.params.tid
    }
  },
  watch: {
    theme: {
      immediate: true,
      handler ({ color }) {
        this.$shipnity.theme.setTheme('light', color)
      }
    }
  }
}
</script>

<style scoped>

</style>
