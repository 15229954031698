<script>

import { VueXTemplate } from '@shipnity/template/src/utils/dom'

export default {
  props: {
    template: {
      type: String,
      default: null
    },
    settings: {
      type: Array,
      default: () => ([])
    },
    data: {
      type: Object,
      default: () => ({
        theme: {}
      })
    }
  },
  mounted () {
    this.sorting()
    this.$emit('init')
  },
  methods: {
    sorting () {
      Array.from(document.querySelectorAll('[data-sequence]'))
        .sort(({ dataset: { sequence: a } }, { dataset: { sequence: b } }) => a.localeCompare(b))
        .forEach((item) => item.parentNode.appendChild(item))
    }
  },
  render (createElement) {
    if (this.template) {
      const keyValues = this.settings
      const xTemplate = new VueXTemplate({
        template: this.template,
        data: this.data
      })
      return xTemplate.combine(keyValues)
    }
    return createElement('div', [''])
  }
}
</script>
